<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height:342px; width:650px">
        <field name="imagen" widget="image" style="width: 54px; height: 54px; top: 4px; left: 6px" />
        <field name="codigo" widget="char" searchable label="Código" :readonly="mode == 'edit'" width="50px"
          style="top:9px; left:80px;" inputStyle="font-weight:bold; text-align:center;" />
        <field name="nombre" widget="char" searchable label="Nombre Familia" help="Nombre Familia" required
          placeholder="Nombre de la Familia" width="220px" style="top:9px; left:144px;" />
        <field name="texto_etiqueta" widget="char" searchable label="Texto Etiqueta" help="Texto Etiqueta" required
          width="220px" style="top:9px; left:379px;" />


        <r-tabs ref="main-tabs" style="top:55px;height:278px;" :buttons="[
          'General',
          'Web',
          'Web 2'
        ]">
          <div class="tab">
            <!--<div class="field" name="publicar_cliente_web" widget="rcswitcher" ontext="Si" offtext="No" label="Publicar Cliente en la Web" width="45" inline-label style="top:42px; right:200px;"/>
            <div class="field" name="re" widget="rcswitcher" ontext="Si" offtext="No" label="Sujeto a Rec. Equivalencia" width="45" inline-label style="top:70px; right:200px;"/>-->
            <fieldset style="position: relative; left: 10px; top: 10px; width: 330px; height: 62px">
              <legend>Unidad de Medida en Compras:</legend>
              <field name="unidad_medida_compra" widget="radio" default="unidad"
                :options="{ 'Unidad': 'unidad', 'Peso': 'peso' }" inline-label style="top:6px; left:15px;" />
              <field name="compra_metal_incluido" widget="checkbox" label="Compra con Metal Incluido" inline-label
                style="top:6px; left:147px;" />
            </fieldset>
            <fieldset style="position: relative; left: 10px; top: 10px; width: 330px; height: 62px">
              <legend>Unidad de Medida en Ventas:</legend>
              <field name="unidad_medida_venta" widget="radio" default="unidad"
                :options="{ 'Unidad': 'unidad', 'Peso': 'peso' }" inline-label style="top:6px; left:15px;" />
              <field name="venta_metal_incluido" widget="checkbox" label="Venta con Metal Incluido" inline-label
                style="top:6px; left:147px;" />
            </fieldset>
            <fieldset style="position: relative; left: 10px; top: 10px; width: 330px; height: 59px">
              <legend>Control Inventario:</legend>
              <field name="control_inventario" widget="radio" default="ambos"
                :options="{ 'Unidad': 'unidad', 'Peso': 'peso', 'Ambos(UyP)': 'ambos' }" inline-label
                style="top:6px; left:60px;" />
            </fieldset>
            <field name="recargo_equivalencia" inline-label widget="toggle" :labels="{ checked: 'Sí', unchecked: 'No' }"
              label="R. Equiv." :width="45" style="top:200px; left:20px;" />

            <field name="app_ocultar" inline-label widget="toggle" :labels="{ checked: 'Sí', unchecked: 'No' }"
              label="Ocultar familia en la APP" :width="45" style="top:200px; left:150px;" />
            <!--<field
              name="dto_especial"
              widget="float"
              label="Dto. Especial"
              inline-label
              help="Descuento Especial"
              required
              width="50px"
              style="top:200px; left:150px;"
              inputalign="center"
              dec="2"
            />-->

            <field name="seccion_id" widget="m2o" dbAdapter="seccion" label="Sección" inline-label width="170px"
              searchable style="top:20px; right:15px;" />

            <field name="tipo_comision_id" widget="m2o" dbAdapter="comision" label="Tipo de Comision"
              labelProp="descripcion" inline-label width="170px" style="top:50px; right:15px;" />
            <field name="atributos" widget="m2m" dbAdapter="atributo" primary="id" label="Atributos por defecto"
              placeholder="Atributos" width="240px" style="top:100px; right:15px;" />
          </div>
          <div class="tab">
            <field name="nombre_web" widget="char" searchable label="Nombre web" placeholder="Nombre web" width="220px"
              style="top:9px; left:10px;" />
            <field name="visible_web" widget="checkbox" label="Visible en la web" inline-label
              style="top:9px; right:10px;" />
            <field name="descripcion" widget="text" label="Descripción" help="Descripción" width="615px" height="40px"
              style="top:50px; left:10px;" />
            <field name="traducciones" widget="handsontable" searchable :height="110" :width="615" :minRows="4"
              style="bottom: 10px; left: 10px;" :htSettings="htSettingsAtributos" :columns="[
                {
                  name: 'idioma',
                  header: 'Idioma',
                  help: 'Español (ES), Inglés (EN), Portugués (PT), Francés (FR)',
                  type: 'select',
                  options: ['ES', 'EN', 'PT', 'FR'],
                },
                {
                  name: 'nombre',
                  header: 'Nombre',
                },
              ]" />
          </div>
          <div class="tab">
            <field name="imagen_web" widget="image" style="width: 180px; height: 180px; top: 10px; left: 10px" />
            <field name="imagen_web2" widget="image" style="width: 392.4px; height: 180px; top: 10px; right: 10px" />
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>

<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Familias",
      dbAdapter: "familia",
      primary: "codigo",
      defaultData: {
        unidad_medida_compra: "unidad",
        unidad_medida_venta: "unidad"
      },
      sequence: {
        name: "familia"
      }
    };
  }
};
</script>
